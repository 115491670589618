import React, { Component, Fragment, type ReactElement } from 'react'
import images from '../images'
import './style.css'

class Footer extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='footer'>
          <div className='footer-topSection'>
            <div className='footer-topLinks'>
              <a href='/message' referrerPolicy='no-referrer'>
                トップメッセージ
              </a>
              <a href='/company' referrerPolicy='no-referrer'>
                会社概要
              </a>
              <a href='/news' referrerPolicy='no-referrer'>
                ニュース
              </a>
              <a href='/careers' referrerPolicy='no-referrer'>
                採用情報
              </a>
              <a href='/mediakit' referrerPolicy='no-referrer'>
                メディアキット
              </a>
            </div>
            <div>
              <a className='footer-contact' href='/contact'>
                <button className='footer-contactButton' type='button'>
                  お問い合わせはこちら
                  <div className='arrow right'></div>
                </button>
              </a>
            </div>
          </div>
          <div className='footer-middleSection'>
            <div className='footer-logoImage'>
              <a href='/'>
                <img alt='footer-logo-image' src={images.footerImage} />
              </a>
            </div>
            <div className='footer-middleLinks'>
              <a href='/privacypolicy' referrerPolicy='no-referrer'>
                プライバシーポリシー
              </a>
              <a href='/infopolicy' referrerPolicy='no-referrer'>
                情報セキュリティポリシー
              </a>
              <a href='/termsandconditions' referrerPolicy='no-referrer'>
                サイト利用規約
              </a>
              <a href='/socialmedia' referrerPolicy='no-referrer'>
                ソーシャルメディア利用規約
              </a>
            </div>
          </div>
          <div className='footer-trademark'>
            <p>© IVA Co., Ltd. All rights reserved.</p>
          </div>
        </div>
        <div id='footer-mobile'>
          <div className='footer-mobile-title'>
            <span>
              AI-DRIVEN and HUMAN-POWERED <br />
              for dynamic future.
            </span>
          </div>
          <a className='footer-mobile-menu-link' href='/message' referrerPolicy='no-referrer'>
            <span>トップメッセージ</span>
          </a>
          <a className='footer-mobile-menu-link' href='/company' referrerPolicy='no-referrer'>
            <span>会社概要</span>
          </a>
          <div className='footer-mobile-menu-link'>
            <div
              className='footer-mobile-menu-link-content'
              onClick={() => this.handleSubmenuToggle('footerSubmenuNews')}
            >
              <span>ニュース</span>
              <div className='footer-mobile-submenu-toggle' id='footerSubmenuNewsToggle'>
                <img alt='plus' src={images.plus} />
              </div>
            </div>
            <div className='footer-mobile-submenu-area' id='footerSubmenuNews'>
              <div className='footer-mobile-submenu-link'>
                <a href='/news?year=2025' referrerPolicy='no-referrer'>
                  2025
                </a>
                <a href='/news?year=2024' referrerPolicy='no-referrer'>
                  2024
                </a>
                <a href='/news?year=2023' referrerPolicy='no-referrer'>
                  2023
                </a>
                <a href='/news?year=2022' referrerPolicy='no-referrer'>
                  2022
                </a>
                <a href='/news?year=2021' referrerPolicy='no-referrer'>
                  2021
                </a>
                <a href='/news?year=2020' referrerPolicy='no-referrer'>
                  2020
                </a>
                <a href='/news?year=2019' referrerPolicy='no-referrer'>
                  2019
                </a>
              </div>
            </div>
          </div>
          <div className='footer-mobile-menu-link'>
            <div
              className='footer-mobile-menu-link-content'
              onClick={() => this.handleSubmenuToggle('footerSubmenuCareers')}
            >
              <span>採用情報</span>
              <div className='footer-mobile-submenu-toggle' id='footerSubmenuCareersToggle'>
                <img alt='plus' src={images.plus} />
              </div>
            </div>
            <div className='footer-mobile-submenu-area' id='footerSubmenuCareers'>
              <div className='footer-mobile-submenu-link'>
                <a href='https://herp.careers/v1/ivacorp' target='_blank' rel='noreferrer'>
                  求人職種一覧
                </a>
                <a
                  href='https://www.wantedly.com/companies/company_6154268'
                  target='_blank'
                  rel='noreferrer'
                >
                  Wantedly 採用ページ
                </a>
              </div>
            </div>
          </div>
          <a className='footer-mobile-menu-link' href='/mediakit' referrerPolicy='no-referrer'>
            <span>メディアキット</span>
          </a>
          <div className='footer-mobile-policy'>
            <a href='/contact' referrerPolicy='no-referrer'>
              お問い合わせ
            </a>
            <a href='/privacypolicy' referrerPolicy='no-referrer'>
              プライバシーポリシー
            </a>
            <a href='/infopolicy' referrerPolicy='no-referrer'>
              情報セキュリティポリシー
            </a>
            <a href='/termsandconditions' referrerPolicy='no-referrer'>
              サイト利用規約
            </a>
            <a href='/socialmedia' referrerPolicy='no-referrer'>
              ソーシャルメディア利用規約
            </a>
          </div>
          <div className='footer-mobile-logo'>
            <img alt='logo' style={{ height: '15px' }} src={images.logo} />
            <span>© IVA Co., Ltd. All rights reserved.</span>
          </div>
        </div>
      </Fragment>
    )
  }

  private readonly handleSubmenuToggle = (id: string): void => {
    const footerSubmenu = document.getElementById(id) as HTMLElement
    const footerSubmenus = document.querySelectorAll(
      '.footer-mobile-submenu-area'
    ) as NodeListOf<HTMLElement>
    const footerSubmenuToggles = document.querySelectorAll(
      '.footer-mobile-submenu-toggle'
    ) as NodeListOf<HTMLElement>

    if (footerSubmenu) {
      for (let i = 0; i < footerSubmenus.length; i++) {
        if (footerSubmenus[i].id === id) {
          if (footerSubmenus[i].style.height !== '') {
            footerSubmenus[i].style.height = ''
            footerSubmenuToggles[i].style.transform = ''
          } else {
            const footerSubmenuHeight = footerSubmenu.scrollHeight
            footerSubmenu.style.height = String(footerSubmenuHeight) + 'px'
            footerSubmenuToggles[i].style.transform = 'rotate(225deg)'
          }
        } else {
          footerSubmenus[i].style.height = ''
          footerSubmenuToggles[i].style.transform = ''
        }
      }
      footerSubmenus.forEach((element) => {})
    }
  }
}

export default Footer
