import images from '../../../common/images'

export const advisor = [
  {
    company: '外部顧問\n清華大学 交差信息研究院 (IIIS)\n准教授',
    profile: images.o1Profile,
    title: '准教授',
    name: '唐平中',
    position: '',
    intro:
        '米国カーネギーメロン大学のコンピュータサイエンス学部で、アルゴリズム的ゲーム理論の研究に従事した後、香港科学技術大学にて博士号を取得。 スタンフォード大学コンピュータサイエンス学部、ハーバード大学コンピュータサイエンス学部、カリフォルニア大学バークレー校、マイクロソフトリサーチアジア(MSRA)にて研究員を経て、Bytedance，Huawei，DiDi，Baidu，CATLのアルゴリズム設計上級顧問を務めつつ、2012年からチューリング賞受賞者のアンドリュー・ヤオによって設立された中国清華大学の交差信息研究院(IIIS)の准教授に就任。2018年 35歳未満の世界トップ35人のイノベーター「MIT-TR35」に選出。'
  }
]
