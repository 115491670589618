/* eslint-disable @typescript-eslint/no-var-requires */

const images = {
  ogimage: require('../images/iva-OGP.jpg').default,
  arrowRight: require('../images/arrow-right.svg').default,
  arrowTop: require('../images/arrow-top.svg').default,
  greyArrow: require('../images/grey-arrow.svg').default,
  asanaLogo: require('../images/asana.svg').default,
  blackLogo: require('../images/iva-logo-blue.svg').default,
  building: require('../images/building-category-icon.svg').default,
  buildingChecked: require('../images/building-category-icon-checked.svg').default,
  buildWithUs: require('../images/careers-build-with-us.png'),
  buildWithUsTitle: require('../images/build_with_us.svg').default,
  blueArrow: require('../images/blue_arrow.svg').default,
  camera: require('../images/camera.svg').default,
  cameraChecked: require('../images/camera_checked.svg').default,
  ceo: require('../images/ceo.png'),
  chairman: require('../images/chairman.png'),
  coo: require('../images/coo.png'),
  careerSlide1: require('../images/car001.jpg'),
  careerSlide2: require('../images/car002.jpg'),
  careerSlide3: require('../images/car003.jpg'),
  careerSlide4: require('../images/car004.jpg'),
  careerSlide5: require('../images/car005.jpg'),
  careersVision: require('../images/careers_vision.svg').default,
  checkMark: require('../images/contact-us-checked.svg').default,
  checkSelected: require('../images/check_selected.svg').default,
  checked: require('../images/checked.svg').default,
  chinaOffice: require('../images/china-office-shanghai.jpg'),
  ceoProfile: require('../images/ceo_profile.jpg'),
  communicationBg: require('../images/communication_bg.png'),
  cooProfile: require('../images/coo_profile.jpg'),
  ctoProfile: require('../images/cto_profile.jpg'),
  chairmanProfile: require('../images/chairman-profile.jpg'),
  oooProfile: require('../images/ooo_profile.jpg'),
  o1Profile: require('../images/01_profile.png'),
  o2Profile: require('../images/02_profile.png'),
  companyAboutUs: require('../images/company_about_us_bg.png'),
  companyBuilding: require('../images/japan_office.jpg'),
  companyPageTitle: require('../images/company_page_title.svg').default,
  cultureDiversity: require('../images/culture-diversity.png'),
  cultureEnthusiasm: require('../images/culture-enthusiasm.png'),
  cultureEntrepreneurship: require('../images/culture-entrepreneurship.png'),
  cultureOpenness: require('../images/culture-openness.png'),
  cultureBg: require('../images/Culture/culture_bg.png'),
  cultureText: require('../images/Culture/culture_text.png'),
  cultureTextImg: require('../images/Culture/culture_text_img.png'),
  cultureTag1: require('../images/Culture/culture_tag_1.png'),
  cultureTag2: require('../images/Culture/culture_tag_2.png'),
  cultureTag3: require('../images/Culture/culture_tag_3.png'),
  cultureTag4: require('../images/Culture/culture_tag_4.png'),
  cultureMobile: require('../images/Culture/culture_mobile.png'),
  missionText: require('../images/Culture/mission_text.png'),
  visionText: require('../images/Culture/vision_text.png'),
  valueText1: require('../images/Culture/value_text_1.png'),
  valueText2: require('../images/Culture/value_text_2.png'),
  valueText3: require('../images/Culture/value_text_3.png'),
  valueText4: require('../images/Culture/value_text_4.png'),
  valueMobile1: require('../images/Culture/value_mobile_1.png'),
  valueMobile2: require('../images/Culture/value_mobile_2.png'),
  valueMobile3: require('../images/Culture/value_mobile_3.png'),
  valueMobile4: require('../images/Culture/value_mobile_4.png'),
  doneCheckMark: require('../images/large_done.svg').default,
  fakebusters: require('../images/fakebusters.png'),
  fakebustersLink: require('../images/fb_link.svg').default,
  fakebustersLinkHover: require('../images/fb_link_hover.svg').default,
  fakebustersLinkBlack: require('../images/fb_link_black.svg').default,
  fakebustersLinkBlackHover: require('../images/fb_link_black_hover.svg').default,
  firstSlideBorderBottom: require('../images/first-slide-border-bottom.svg').default,
  fakeNews1: require('../images/mock_news_1.png'),
  fakeNews2: require('../images/mock_news_2.png'),
  fakeNews3: require('../images/mock_news_3.png'),
  fakeNews4: require('../images/mock_news_4.png'),
  footerImage: require('../images/footer-iva.svg').default,
  greyArrowRight: require('../images/grey-arrow-right.svg').default,
  info: require('../images/info.svg').default,
  infoChecked: require('../images/info_checked.svg').default,
  inspection: require('../images/news_details_inspect.png'),
  logo: require('../images/iva-logo.svg').default,
  largeGreyArrowRight: require('../images/large-grey-arrow.svg').default,
  largeGreyArrowLeft: require('../images/large-grey-arrow-left.svg').default,
  largeWhiteArrowLeft: require('../images/large-white-arrow.svg').default,
  largeWhiteArrowRight: require('../images/large-white-arrow-right.svg').default,
  handshake: require('../images/handshake.svg').default,
  handshakeChecked: require('../images/handshake_checked.svg').default,
  headerBackground: require('../images/title-bg.png'),
  newJapanOffice: require('../images/newJapan-Office.jpg'),
  japanOffice: require('../images/japan_office.jpg'),
  paginatorRight: require('../images/paginator_right.svg').default,
  paginatorLeft: require('../images/paginator_left.svg').default,
  peopleChecked: require('../images/people.svg').default,
  people: require('../images/people_checked.svg').default,
  plus: require('../images/plus.svg').default,
  questionMark: require('../images/question-mark.svg').default,
  secondSlideBorderBottom: require('../images/second-slide-border-bottom.svg').default,
  servicesBackground1: require('../images/service_bg_1.png'),
  // Message
  messageBanner: require('../images/Message/ceo.png'),
  messageLogo: require('../images/Message/logo.png'),
  messageSign: require('../images/Message/sign.png'),
  // Service
  serviceImage1: require('../images/Service/Fakebusters.jpg'),
  serviceImage2: require('../images/service-snex.jpg'),
  serviceImage3: require('../images/Service/circular-economy.jpg'),
  slackLogo: require('../images/slack.svg').default,
  slide1GroupedImgs: require('../images/group_slide_1.png'),
  slide1GroupedImgsMobile: require('../images/group_slide_1_mobile.svg').default,
  slide2GroupedImgs: require('../images/group_slide_2.png'),
  slide2GroupedImgsMobile: require('../images/group_slide_2_mobile.png'),
  taiwanOffice: require('../images/taiwan_office.jpg'),
  teamViewerLogo: require('../images/team_viewer.svg').default,
  valuesBg: require('../images/values_bg.png'),
  xIcon: require('../images/cancel_icon.svg').default,
  moreWhite: require('../images/more_white.svg').default,
  moreBlack: require('../images/more_black.svg').default,
  zoomLogo: require('../images/zoom.svg').default,
  notionLogo: require('../images/notionlogo.svg').default,
// Careers
  careersTitle: require('../images/Careers/careers_title.png'),
  careersTitle2: require('../images/Careers/careers_title_2.png'),
  careersTitleImage: require('../images/Careers/careers_title_image.png'),
  careersTitleMobile: require('../images/Careers/careers_title_mobile.png'),
  careersOurTeam: require('../images/Careers/our_team.png'),
  careersOurTeam1: require('../images/Careers/our_team_1.png'),
  careersOurTeam2: require('../images/Careers/our_team_2.png'),
  careersOurTeam3: require('../images/Careers/our_team_3.png'),
  careersOurTeam4: require('../images/Careers/our_team_4.png'),
  careersOurTeam5: require('../images/Careers/our_team_5.png'),
  careersOurTeam6: require('../images/Careers/our_team_6.png'),
  careersOurTeam7: require('../images/Careers/our_team_7.png'),
  careersOurTeam8: require('../images/Careers/our_team_8.png'),
  careersOurTeamMobile1: require('../images/Careers/our_team_mobile_1.png'),
  careersOurTeamMobile2: require('../images/Careers/our_team_mobile_2.png'),
  careersOurTeamMobile3: require('../images/Careers/our_team_mobile_3.png'),
  careersOurTeamMobile4: require('../images/Careers/our_team_mobile_4.png'),
  careersOurTeamMobile5: require('../images/Careers/our_team_mobile_5.png'),
  careersOurTeamMobile6: require('../images/Careers/our_team_mobile_6.png'),
  careersOurTeamMobile7: require('../images/Careers/our_team_mobile_7.png'),
  careersOurTeamMobile8: require('../images/Careers/our_team_mobile_8.png'),
  careersWelfareBenefits1: require('../images/Careers/welfare_benefits_1.png'),
  careersWelfareBenefits2: require('../images/Careers/welfare_benefits_2.png'),
  careersWelfareBenefits3: require('../images/Careers/welfare_benefits_3.png'),
  careersWelfareBenefits4: require('../images/Careers/welfare_benefits_4.png'),
  careersWelfareBenefits5: require('../images/Careers/welfare_benefits_5.png'),
  careersWelfareBenefits6: require('../images/Careers/welfare_benefits_6.png'),
  careersWelfareBenefits7: require('../images/Careers/welfare_benefits_7.png'),
  careersWelfareBenefits8: require('../images/Careers/welfare_benefits_8.png'),
  careersWelfareBenefits9: require('../images/Careers/welfare_benefits_9.png'),
  careersWelfareBenefits10: require('../images/Careers/welfare_benefits_10.png'),
  careersWelfareBenefits11: require('../images/Careers/welfare_benefits_11.png'),
  careersWelfareBenefits12: require('../images/Careers/welfare_benefits_12.png'),
  careersWelfareBenefits13: require('../images/Careers/welfare_benefits_13.png'),
  careersWelfareBenefits14: require('../images/Careers/welfare_benefits_14.png'),
  careersWelfareBenefits15: require('../images/Careers/welfare_benefits_15.png'),
  careersWelfareBenefits16: require('../images/Careers/welfare_benefits_16.png'),
  careersWelfareBenefitsMobile1: require('../images/Careers/welfare_benefits_mobile_1.png'),
  careersWelfareBenefitsMobile2: require('../images/Careers/welfare_benefits_mobile_2.png'),
  careersWelfareBenefitsMobile3: require('../images/Careers/welfare_benefits_mobile_3.png'),
  careersWelfareBenefitsMobile4: require('../images/Careers/welfare_benefits_mobile_4.png'),
  careersWelfareBenefitsMobile5: require('../images/Careers/welfare_benefits_mobile_5.png'),
  careersWelfareBenefitsMobile6: require('../images/Careers/welfare_benefits_mobile_6.png'),
  careersWelfareBenefitsMobile7: require('../images/Careers/welfare_benefits_mobile_7.png'),
  careersWelfareBenefitsMobile8: require('../images/Careers/welfare_benefits_mobile_8.png'),
  careersWelfareBenefitsMobile9: require('../images/Careers/welfare_benefits_mobile_9.png'),
  careersWelfareBenefitsMobile10: require('../images/Careers/welfare_benefits_mobile_10.png'),
  careersWelfareBenefitsMobile11: require('../images/Careers/welfare_benefits_mobile_11.png'),
  careersWelfareBenefitsMobile12: require('../images/Careers/welfare_benefits_mobile_12.png'),
  careersWelfareBenefitsMobile13: require('../images/Careers/welfare_benefits_mobile_13.png'),
  careersWelfareBenefitsMobile14: require('../images/Careers/welfare_benefits_mobile_14.png'),
  careersWelfareBenefitsMobile15: require('../images/Careers/welfare_benefits_mobile_15.png'),
  careersWelfareBenefitsMobile16: require('../images/Careers/welfare_benefits_mobile_16.png'),
  careersCommunication: require('../images/Careers/communication.png'),
  careersCommunicationMobile: require('../images/Careers/communication_mobile.png'),
  careersGallery: require('../images/Careers/gallery.png'),
  careersGallery1: require('../images/Careers/gallery_1.png'),
  careersGallery2: require('../images/Careers/gallery_2.png'),
  careersGallery3: require('../images/Careers/gallery_3.png'),
  careersGallery4: require('../images/Careers/gallery_4.png'),
  careersGallery5: require('../images/Careers/gallery_5.png'),
  careersGallery6: require('../images/Careers/gallery_6.png'),
  careersGallery7: require('../images/Careers/gallery_7.png'),
  careersGallery8: require('../images/Careers/gallery_8.png'),
  careersGallery9: require('../images/Careers/gallery_9.png'),
  careersGalleryMobile: require('../images/Careers/gallery_mobile.png'),
  careersRecruitingPhilosorhyText: require('../images/Careers/recruiting_philosorhy_text.png'),
  careersRecruitingPhilosorhyMobile1: require('../images/Careers/recruiting_philosorhy_mobile_1.png'),
  careersRecruitingPhilosorhyMobile2: require('../images/Careers/recruiting_philosorhy_mobile_2.png'),
  careersCeo: require('../images/Careers/ceo.png'),
  careersDesigner: require('../images/Careers/designer.png'),
  careersDesigner2: require('../images/Careers/designer2.png'),
  wantedlyLink: require('../images/Careers/wantedly.png'),
  wantedlyLinkHover: require('../images/Careers/wantedly_hover.png'),
  wantedlyLogo: require('../images/wantedly_logo.png'),
  valueCardBg1: require('../images/value_card_bg.png'),
  valueCardBg2: require('../images/value_card_bg2.png'),
  valueCardBg3: require('../images/value_card_bg3.png'),
  valueCardBg4: require('../images/value_card_bg4.png'),
  cultureImg: require('../images/career-culture-img.png'),
  ivaArtBg0: require('../images/iva-art-0.png'),
  ivaArtBg1: require('../images/iva-art-1.png'),
  ivaArtBg2: require('../images/iva-art-2.png'),
  ivaArtBg3: require('../images/iva-art-3.png'),
  ivaArtBg4: require('../images/iva-art-4.png'),
  // Mediakit
  logoIvaBlackPng: require('../images/Logos/iva/png/logo-iva-black.png'),
  logoIvaColorPng: require('../images/Logos/iva/png/logo-iva-color.png'),
  logoIvaWhitePng: require('../images/Logos/iva/png/logo-iva-white.png'),
  logoIvaBlackSvg: require('../images/Logos/iva/svg/logo-iva-black.svg').default,
  logoIvaColorSvg: require('../images/Logos/iva/svg/logo-iva-color.svg').default,
  logoIvaWhiteSvg: require('../images/Logos/iva/svg/logo-iva-white.svg').default,
  logoIvaForPrintSvg: require('../images/Logos/iva/iva-logo-kit2401a.svg').default,
  logoIvaForPrint: require('../images/Logos/iva/iva-logo-kit2401a.ai'),
  logoIvaGuideine: require('../images/Logos/iva/guideline-iva.jpg'),
  logoIvaGuideineArray: [
    require('../images/Logos/iva/brandGuidelines/bg1.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg2.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg3.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg4.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg5.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg6.jpg'),
    require('../images/Logos/iva/brandGuidelines/bg7.jpg')
  ],
  logoFbAppBlackPng: require('../images/Logos/fakebusters/png/logo-fb-app-black.png'),
  logoFbAppColorPng: require('../images/Logos/fakebusters/png/logo-fb-app-color.png'),
  logoFbAppWhitePng: require('../images/Logos/fakebusters/png/logo-fb-app-white.png'),
  logoFbAppolBlackPng: require('../images/Logos/fakebusters/png/logo-fb-appol-black.png'),
  logoFbAppolColorPng: require('../images/Logos/fakebusters/png/logo-fb-appol-color.png'),
  logoFbAppolWhitePng: require('../images/Logos/fakebusters/png/logo-fb-appol-white.png'),
  logoFbHorizontalBlackPng: require('../images/Logos/fakebusters/png/logo-fb-horizontal-black.png'),
  logoFbHorizontalColorPng: require('../images/Logos/fakebusters/png/logo-fb-horizontal-color.png'),
  logoFbHorizontalWhitePng: require('../images/Logos/fakebusters/png/logo-fb-horizontal-white.png'),
  logoFbTrademarkBlackPng: require('../images/Logos/fakebusters/png/logo-fb-trademark-black.png'),
  logoFbTrademarkColorPng: require('../images/Logos/fakebusters/png/logo-fb-trademark-color.png'),
  logoFbTrademarkWhitePng: require('../images/Logos/fakebusters/png/logo-fb-trademark-white.png'),
  logoFbVerticalBlackPng: require('../images/Logos/fakebusters/png/logo-fb-vertical-black.png'),
  logoFbVerticalColorPng: require('../images/Logos/fakebusters/png/logo-fb-vertical-color.png'),
  logoFbVerticalWhitePng: require('../images/Logos/fakebusters/png/logo-fb-vertical-white.png'),
  logoFbAppBlackSvg: require('../images/Logos/fakebusters/svg/logo-fb-app-black.svg').default,
  logoFbAppColorSvg: require('../images/Logos/fakebusters/svg/logo-fb-app-color.svg').default,
  logoFbAppWhiteSvg: require('../images/Logos/fakebusters/svg/logo-fb-app-white.svg').default,
  logoFbAppolBlackSvg: require('../images/Logos/fakebusters/svg/logo-fb-appol-black.svg').default,
  logoFbAppolColorSvg: require('../images/Logos/fakebusters/svg/logo-fb-appol-color.svg').default,
  logoFbAppolWhiteSvg: require('../images/Logos/fakebusters/svg/logo-fb-appol-white.svg').default,
  logoFbHorizontalBlackSvg: require('../images/Logos/fakebusters/svg/logo-fb-horizontal-black.svg').default,
  logoFbHorizontalColorSvg: require('../images/Logos/fakebusters/svg/logo-fb-horizontal-color.svg').default,
  logoFbHorizontalWhiteSvg: require('../images/Logos/fakebusters/svg/logo-fb-horizontal-white.svg').default,
  logoFbTrademarkBlackSvg: require('../images/Logos/fakebusters/svg/logo-fb-trademark-black.svg').default,
  logoFbTrademarkColorSvg: require('../images/Logos/fakebusters/svg/logo-fb-trademark-color.svg').default,
  logoFbTrademarkWhiteSvg: require('../images/Logos/fakebusters/svg/logo-fb-trademark-white.svg').default,
  logoFbVerticalBlackSvg: require('../images/Logos/fakebusters/svg/logo-fb-vertical-black.svg').default,
  logoFbVerticalColorSvg: require('../images/Logos/fakebusters/svg/logo-fb-vertical-color.svg').default,
  logoFbVerticalWhiteSvg: require('../images/Logos/fakebusters/svg/logo-fb-vertical-white.svg').default,
  logofakebustersForPrintSvg: require('../images/Logos/fakebusters/fakebusters-logo-kit2310a.svg').default,
  logofakebustersForPrint: require('../images/Logos/fakebusters/fakebusters-logo-kit2310a.ai'),
  logofakebustersGuideline: require('../images/Logos/fakebusters/guideline-fakebusters.jpg'),
  logofakebustersGuideineArray: [
    require('../images/Logos/fakebusters/brandGuidelines/bg1.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg2.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg3.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg4.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg5.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg6.jpg'),
    require('../images/Logos/fakebusters/brandGuidelines/bg7.jpg')
  ]
}

export default images
