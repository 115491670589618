import React, { Component, type ReactElement } from 'react'
import images from '../../../common/images'
import { animationDelayMilliseconds } from '../../../common/config'
import './style.css'

class RecruitingStepsMobile extends Component {
  componentDidMount = (): void => {
    this.setAnimationCounter(0)
  }

  componentWillUnmount(): void {
    this.terminateAnimation()
  }

  render(): ReactElement {
    return (
      <div className='careers-recruitingSteps' data-aos='goin'>
        <span className='careers-stepWrapper first-row'>
          <span className='careers-recruitingStep careers-step'>
            <span className='tag'>STEP01</span>
            <br />
            応募
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep careers-step'>
            <span className='tag'>STEP02</span>
            <br />
            書類選考
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep careers-step'>
            <span className='tag'>STEP03</span>
            <br />
            一次面接
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
        </span>
        <span className='careers-stepWrapper second-row'>
          <span className='careers-recruitingStep careers-step'>
            <span className='tag'>STEP04</span>
            <br />
            二次面接
          </span>
          <img alt='recruiting-steps-arrow' src={images.blueArrow} />
          <span className='careers-recruitingStep careers-step step-5'>
            <span className='tag'>STEP05</span>
            <br />
            <span>
              内定<b>・</b>入社
            </span>
          </span>
        </span>
      </div>
    )
  }

  private readonly setAnimationCounter = (index: number): void => {
    if (index === 100) {
      return
    }

    const steps = Array.from(document.querySelectorAll('.careers-step')) as HTMLElement[]
    const prevStep = steps[index === 0 ? steps.length - 1 : index - 1]
    const currentStep = steps[index]

    if (steps.length) {
      if (currentStep) {
        currentStep.className = 'careers-currentStep careers-step'
      }
      if (prevStep) {
        prevStep.className = 'careers-recruitingStep careers-step'
      }

      setTimeout(() => {
        this.setAnimationCounter(index + 1 === steps.length ? 0 : index + 1)
      }, animationDelayMilliseconds)
    }
  }

  private readonly terminateAnimation = (): void => {
    this.setAnimationCounter(100)
  }
}

export default RecruitingStepsMobile
