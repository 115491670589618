import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import images from '../../common/images'
import BackToTop from '../../common/BackToTop/BackToTop'
import RecruitingStepsPc from './RecruitingStepsPc/RecruitingStepsPc'
import RecruitingStepsMobile from './RecruitingStepsMobile/RecruitingStepsMobile'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import './style.scss'
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as AOS from 'aos'
import 'aos/dist/aos.css'

class Careers extends Component {
  private cultureInterval: NodeJS.Timeout | undefined
  componentDidMount(): void {
    AOS.init({
      duration: 600,
      once: true,
      mirror: false
    })
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜採用情報'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
    // Set up interval to cycle active class
    this.cultureInterval = setInterval(() => {
      const features = document.querySelectorAll('.careers-culture-feature')
      const images = document.querySelectorAll('.culture-img')
      if (features.length > 0) {
        let activeIndex = -1
        features.forEach((feature, index) => {
          if (feature.classList.contains('actived')) {
            activeIndex = index
            feature.classList.remove('actived')
          }
        })
        const nextIndex = (activeIndex + 1) % features.length
        features[nextIndex].classList.add('actived')

        images.forEach((image, index) => {
          if (index === nextIndex) {
            image.classList.add('active')
          } else {
            image.classList.remove('active')
          }
        })
      }
    }, 5000)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
    // Clear the interval
    if (this.cultureInterval) {
      clearInterval(this.cultureInterval)
    }
  }

  render(): ReactElement {
    const qa = {
      selection: [
        {
          title: 'どの職種で応募するか迷っています。複数の職種への応募は可能ですか？',
          content:
            'オープンポジションにてご応募ください！希望や適性を見てご案内させていただきます。'
        },
        {
          title: '応募する前に働き方や条件について詳しく話を聞きたい点があります。',
          content:
            'エントリー後のメッセージにてその旨をお知らせください。選考前にカジュアル面談を実施させていただければと思います。'
        },
        {
          title: 'オンラインでの面接は可能ですか？',
          content:
            '基本的に一次面接はオンライン、最終面接は対面での実施をお願いしています。一次面接も対面をご希望の方は対応可能ですのでお知らせください。'
        },
        {
          title: '過去にIVAの選考を受けたのですが、再びエントリーすることは可能ですか？',
          content:
            'もちろん可能です。募集ポジションや職務内容は随時更新しているため、ご確認の上、ご応募ください。'
        }
      ],
      business: [
        {
          title: 'どのように社内でコミュニケーションをとっていますか？',
          content:
            'Slack、ZoomなどのコミュニケーションツールやNotionなどのドキュメントサービスを使い、円滑なコミュニケーションや情報の透明性が保たれるようにしています。対面でのコミュニケーションも積極的に実施していて、社員同士のランチなどの活動もサポートしています。'
        },
        {
          title: 'リモートで働くことは可能ですか？',
          content: 'リモートワークは現在検討中で、原則出社をお願いしています。'
        }
        // {
        //   title: '副業は可能ですか？',
        //   content: '別途申請いただければ、業務に支障が出ない範囲で可能です。'
        // }
      ]
    }

    const pickup = [
      {
        title: '代表インタビュー：<br>ガムシャラに、粛々と。CEO相原嘉夫が語るIVAの軌跡と展望',
        content:
          '今回は創業者CEOの相原嘉夫に生い立ちやIVAの創業秘話、事業にかける想いをインタビューしました。',
        img: images.careersCeo,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/888581'
      },
      {
        title: 'インタビュー：<br>BizDev VP：手を挙げることで拓く、自身と組織の成長',
        content:
          '今回はコーポレート部統括マネージャの玉野菫にIVA入社の契機や今後の展望をインタビューしました。',
        img: images.careersDesigner2,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/918917'
      },
      {
        title: 'インタビュー：<br>シニア UXデザイナー：デザインを通して組織の共創を加速する',
        content:
          '今回はシニアデザイナーの塩澤源太にIVA入社の契機や今後の展望をインタビューしました。',
        img: images.careersDesigner,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/889132'
      }
    ]

    const welfareBenefitsImages = [
      images.careersWelfareBenefits1,
      images.careersWelfareBenefits2,
      images.careersWelfareBenefits3,
      images.careersWelfareBenefits4,
      images.careersWelfareBenefits5,
      images.careersWelfareBenefits6,
      images.careersWelfareBenefits7,
      images.careersWelfareBenefits8,
      images.careersWelfareBenefits9,
      images.careersWelfareBenefits10,
      images.careersWelfareBenefits11,
      images.careersWelfareBenefits12,
      images.careersWelfareBenefits13,
      images.careersWelfareBenefits14,
      images.careersWelfareBenefits15,
      images.careersWelfareBenefits16
    ]

    const welfareBenefitsMobileImages = [
      images.careersWelfareBenefitsMobile1,
      images.careersWelfareBenefitsMobile2,
      images.careersWelfareBenefitsMobile3,
      images.careersWelfareBenefitsMobile4,
      images.careersWelfareBenefitsMobile5,
      images.careersWelfareBenefitsMobile6,
      images.careersWelfareBenefitsMobile7,
      images.careersWelfareBenefitsMobile8,
      images.careersWelfareBenefitsMobile9,
      images.careersWelfareBenefitsMobile10,
      images.careersWelfareBenefitsMobile11,
      images.careersWelfareBenefitsMobile12,
      images.careersWelfareBenefitsMobile13,
      images.careersWelfareBenefitsMobile14,
      images.careersWelfareBenefitsMobile15,
      images.careersWelfareBenefitsMobile16
    ]

    const galleryMobileImages = [
      images.careersGallery1,
      images.careersGallery2,
      images.careersGallery3,
      images.careersGallery4,
      images.careersGallery5,
      images.careersGallery6,
      images.careersGallery7,
      images.careersGallery8,
      images.careersGallery9
    ]

    return (
      <Fragment>
        <div className='careers main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <div id='careersPc'>
            <div id='careersTitle'>
              <div></div>
              <div></div>
            </div>
            <div
              className='careers-container'
              style={{
                paddingTop: '40px',
                paddingBottom: '120px',
                backgroundColor: 'rgb(247, 247, 247)'
              }}
            >
              <div className='careers-divider'></div>
              <div className='careers-container-title' data-aos='goin'>
                <span>Our Team</span>
                <span className='careers-container-title-sub-title'>私たちのチーム</span>
              </div>
              <div className='careers-container-our-team' data-aos='goin'>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam1})`,
                    aspectRatio: 245 / 256,
                    flex: 245
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam2})`,
                    aspectRatio: 245 / 256,
                    flex: 245
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam3})`,
                    aspectRatio: 375 / 256,
                    flex: 375
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam4})`,
                    aspectRatio: 245 / 256,
                    flex: 245
                  }}
                ></div>
              </div>
              <div className='careers-container-our-team' data-aos='goin'>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam5})`,
                    aspectRatio: 164 / 271,
                    flex: 164
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam6})`,
                    aspectRatio: 245 / 271,
                    flex: 245
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam7})`,
                    aspectRatio: 354 / 271,
                    flex: 354
                  }}
                ></div>
                <div
                  className='careers-container-our-team-img'
                  style={{
                    backgroundImage: `url(${images.careersOurTeam8})`,
                    aspectRatio: 347 / 271,
                    flex: 347
                  }}
                ></div>
              </div>
              <div className='careers-container-title' data-aos='goin'>
                <span>Welfare Benefits</span>
                <span className='careers-container-title-sub-title'>福利厚生</span>
              </div>
              <div className='careers-container-welfare-benefits'>
                {welfareBenefitsImages.map((item, index) => (
                  <img src={item} key={index} alt='welfare benefit' data-aos='goin' />
                ))}
              </div>
            </div>
            <div
              className='careers-container'
              style={{
                paddingTop: '100px'
              }}
            >
              <div className='careers-container-title' style={{ margin: '0' }} data-aos='goin'>
                <span>Communication</span>
                <span className='careers-container-title-sub-title'>コミュニケーション</span>
              </div>
              <div className='careers-container-communication' data-aos='goin'>
                <p>
                  社内ではオンラインツールを活用し、プロジェクトやチームを横断したコミュニケーション・ナレッジ共有が積極的に行われています。また、Face
                  to Face
                  の対話も重要視し、オープンで快適なオフィス環境を構築しています。さらに、日本や台湾、中国といったIVAの拠点だけでなく、外部のパートナーとのコラボレーションも活発に行っています。
                </p>
                <img src={images.careersCommunication} alt='careersCommunication' />
              </div>
              <div className='careers-container-title' data-aos='goin'>
                <span>Pick up</span>
                <span className='careers-container-title-sub-title'>ピックアップ</span>
              </div>
              <div id='pickUpPc'>
                {pickup.map((item, index) => (
                  <div className='pick-up-card' key={index} data-aos='goin'>
                    <div className='pick-up-content'>
                      <a
                        className='pick-up-title'
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></a>
                      <p>{item.content}</p>
                      <a
                        className='pick-up-wantedly'
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                      ></a>
                    </div>
                    <div className='pick-up-img'>
                      <a
                        className='pick-up-main-img'
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img src={item.img} alt='pickUpMainImage' />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='careers-container' style={{ paddingTop: '0' }}>
              <div className='careers-container-title' style={{ margin: '40 0' }} data-aos='goin'>
                <span>Gallery</span>
                <span className='careers-container-title-sub-title'>ギャラリー</span>
              </div>
              <div id='gallery-area'>
                <div className='gallery-items' data-aos='goin'>
                  <img src={images.careersGallery1} alt='careersGallery1' />
                  <img src={images.careersGallery2} alt='careersGallery2' />
                  <img src={images.careersGallery3} alt='careersGallery3' />
                </div>
                <div className='gallery-items' data-aos='goin'>
                  <img src={images.careersGallery4} alt='careersGallery4' />
                  <img src={images.careersGallery5} alt='careersGallery5' />
                  <img src={images.careersGallery6} alt='careersGallery6' />
                </div>
                <div className='gallery-items' data-aos='goin'>
                  <img src={images.careersGallery7} alt='careersGallery7' />
                  <img src={images.careersGallery8} alt='careersGallery8' />
                  <img src={images.careersGallery9} alt='careersGallery9' />
                </div>
              </div>
            </div>
            <div className='careers-container'>
              <div className='careers-container-title' style={{ lineHeight: 'normal' }} data-aos='goin'>
                <span>Recruiting Philosophy</span>
                <span className='careers-container-title-sub-title'>採用フロー</span>
              </div>
              <div className='careers-container-recruiting-philosophy' data-aos='goin'>
                <p>
                  仲間を探すプロセスに、
                  <br />
                  妥協しない
                </p>
                <p>
                  IVAは「物事を多角的に理解することこそが、最適な意思決定に繋がる」という考えを大切にしています。このため、私たちは「会話」を重視し、採用においてもご応募いただく皆さまとの会話の機会を多く設けています。
                  <br />
                  相互理解を深め、「一緒に楽しいことができそうだ」と確信したうえでJoinすることは、大きな価値を創出するためには重要です。そのため、私たちの採用プロセスは皆さまにとっても価値のあるものであると考えています。
                </p>
              </div>
              <RecruitingStepsPc />
              <p style={{ placeSelf: 'center' }}>
                入社後のミスマッチを双方で防ぐため、必要に応じて採用フローを変更する場合があります。
              </p>
            </div>
            <div
              className='careers-contactUs'
              data-aos='goin'
              style={{ marginTop: '50px', marginBottom: 0 }}
            >
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a
                href='https://www.wantedly.com/companies/company_6154268'
                target='_blank'
                rel='noreferrer'
              >
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    Wantedly 採用ページ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
            <div className='careers-container'>
              <div className='careers-container-title' style={{ marginTop: 0 }} data-aos='goin'>
                <span>Q&A</span>
                <span className='careers-container-title-sub-title'>よくある質問</span>
              </div>
              <div className='accordion-block' data-aos='goin'>
                <span className='accordion-block-title'>応募選考プロセス</span>
                {qa.selection.map((item, index) => (
                  <div className='accordion-item' key={index}>
                    <div
                      className='accordion-item-title'
                      onClick={(event) =>
                        this.expandDescription(event.currentTarget, 'selection', index)
                      }
                    >
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='selection-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='selection-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='accordion-block' data-aos='goin'>
                <span className='accordion-block-title'>業務内容・カルチャー</span>
                {qa.business.map((item, index) => (
                  <div className='accordion-item' key={index}>
                    <div
                      className='accordion-item-title'
                      onClick={(event) =>
                        this.expandDescription(event.currentTarget, 'business', index)
                      }
                    >
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='business-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='business-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='careers-contactUs' data-aos='goin'>
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a
                href='https://www.wantedly.com/companies/company_6154268'
                target='_blank'
                rel='noreferrer'
              >
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    Wantedly 採用ページ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
          </div>

          <div id='careersMobile'>
            <div id='careersTitleMobile'>
              <div></div>
              <div></div>
            </div>
            <div className='mobile-our-team-container'>
              <div className='careers-divider' data-aos='goin'></div>
              <div className='mobile-title' data-aos='goin'>
                <span>Our Team</span>
                <span className='mobile-title-sub'>私たちのチーム</span>
              </div>
              <div className='mobile-our-team-container-our-team'>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile1})`,
                      aspectRatio: 170 / 221,
                      flex: 170
                    }}
                  ></div>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile2})`,
                      aspectRatio: 170 / 221,
                      flex: 170
                    }}
                  ></div>
                </div>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile3})`,
                      aspectRatio: 350 / 243,
                      flex: 350
                    }}
                  ></div>
                </div>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile4})`,
                      aspectRatio: 176 / 180,
                      flex: 176
                    }}
                  ></div>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile5})`,
                      aspectRatio: 164 / 180,
                      flex: 164
                    }}
                  ></div>
                </div>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile6})`,
                      aspectRatio: 350 / 243,
                      flex: 350
                    }}
                  ></div>
                </div>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile7})`,
                      aspectRatio: 350 / 268,
                      flex: 350
                    }}
                  ></div>
                </div>
                <div className='mobile-our-team-container-our-team-content' data-aos='goin'>
                  <div
                    className='mobile-our-team-container-our-team-content-img'
                    style={{
                      backgroundImage: `url(${images.careersOurTeamMobile8})`,
                      aspectRatio: 347 / 267,
                      flex: 347
                    }}
                  ></div>
                </div>
              </div>
              <div className='mobile-title' data-aos='goin'>
                <span>Welfare Benefits</span>
                <span className='mobile-title-sub'>福利厚生</span>
              </div>
              <div className='mobile-our-team-container-welfare-benefits'>
                {welfareBenefitsMobileImages.map((item, index) => (
                  <img src={item} key={index} alt='welfare benefit' data-aos='goin' />
                ))}
              </div>
            </div>
            <div className='mobile-communication-container'>
              <div className='mobile-title' data-aos='goin'>
                <span>Communication</span>
                <span className='mobile-title-sub'>コミュニケーション</span>
              </div>
              <div className='mobile-communication-container-communication' data-aos='goin'>
                <p>
                  社内ではオンラインツールを活用し、プロジェクトやチームを横断したコミュニケーション・ナレッジ共有が積極的に行われています。また、Face
                  to Face
                  の対話も重要視し、オープンで快適なオフィス環境を構築しています。さらに、日本や台湾、中国といったIVAの拠点だけでなく、外部のパートナーとのコラボレーションも活発に行っています。
                </p>
                <img src={images.careersCommunication} alt='careersCommunication' />
              </div>
              <div className='mobile-title' data-aos='goin'>
                <span>Pick up</span>
                <span className='mobile-title-sub'>ピックアップ</span>
              </div>
              <div id='pickUpMobile'>
                {pickup.map((item, index) => (
                  <div className='pick-up-card' key={index} data-aos='goin'>
                    <a
                      className='pick-up-card-main-img'
                      href={item.link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={item.img} alt='pickUpMainImage' />
                    </a>
                    <div className='pick-up-card-content'>
                      <a
                        className='pick-up-card-content-title'
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></a>
                      <p>{item.content}</p>
                      <a
                        className='pick-up-card-content-wantedly'
                        href={item.link}
                        target='_blank'
                        rel='noreferrer'
                      ></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='mobile-gallery-container'>
              <div className='mobile-title' data-aos='goin'>
                <span>Gallery</span>
                <span className='mobile-title-sub'>ギャラリー</span>
              </div>
              <div className='mobile-gallery-container-gallery'>
                {galleryMobileImages.map((item, index) => (
                  <img src={item} key={index} alt='careersGalleryMobile' data-aos='goin' />
                ))}
              </div>
            </div>
            <div className='mobile-recruiting-philosorhy-container'>
              <div className='mobile-title' data-aos='goin'>
                <span>Recruiting Philosorhy</span>
                <span className='mobile-title-sub'>採用フロー</span>
              </div>
              <div className='mobile-recruiting-philosorhy-container-content'>
                <div
                  className='mobile-recruiting-philosorhy-container-content-text'
                  data-aos='goin'
                >
                  <p className='mobile-recruiting-philosorhy-container-content-text-title'>
                    仲間を探すプロセスに、
                    <br />
                    妥協しない
                  </p>
                  <p className='mobile-recruiting-philosorhy-container-content-text-text'>
                    IVAは「物事を多角的に理解することこそが、最適な意思決定に繋がる」という考えを大切にしています。このため、私たちは「会話」を重視し、採用においてもご応募いただく皆さまとの会話の機会を多く設けています。
                    <br />
                    相互理解を深め、「一緒に楽しいことができそうだ」と確信したうえでJoinすることは、大きな価値を創出するためには重要です。そのため、私たちの採用プロセスは皆さまにとっても価値のあるものであると考えています。
                  </p>
                </div>
                <RecruitingStepsMobile />
                <p
                  className='mobile-recruiting-philosorhy-container-content-sub-text'
                  data-aos='goin'
                >
                  入社後のミスマッチを双方で防ぐため、必要に応じて採用フローを変更する場合があります。
                </p>
              </div>
              <div className='careers-contactUs'>
                <a
                  href='https://herp.careers/v1/iva0403'
                  target='_blank'
                  rel='noreferrer'
                  data-aos='goin'
                >
                  <button className='nav-button button' type='button'>
                    <div className='nav-buttonText'>
                      職種を見る
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
                <a
                  href='https://www.wantedly.com/companies/company_6154268'
                  target='_blank'
                  rel='noreferrer'
                  data-aos='goin'
                >
                  <button className='nav-button button' type='button'>
                    <div className='nav-buttonText'>
                      Wantedly 採用ページ
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
              </div>
            </div>
            <div className='mobile-qa-container'>
              <div className='mobile-title' data-aos='goin'>
                <span>Q&A</span>
                <span className='mobile-title-sub'>よくある質問</span>
              </div>
              <div className='accordion-block' style={{ paddingBottom: 0 }} data-aos='goin'>
                <span className='accordion-block-title'>応募選考プロセス</span>
                {qa.selection.map((item, index) => (
                  <div
                    className='accordion-item'
                    key={index}
                    onClick={(event) =>
                      this.expandDescription(event.currentTarget, 'selection-mobile', index)
                    }
                  >
                    <div className='accordion-item-title'>
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='selection-mobile-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='selection-mobile-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className='accordion-block'
                style={{ paddingTop: 0, paddingBottom: '50px' }}
                data-aos='goin'
              >
                <span className='accordion-block-title'>業務内容・カルチャー</span>
                {qa.business.map((item, index) => (
                  <div
                    className='accordion-item'
                    key={index}
                    onClick={(event) =>
                      this.expandDescription(event.currentTarget, 'business-mobile', index)
                    }
                  >
                    <div className='accordion-item-title'>
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='business-mobile-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='business-mobile-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='careers-contactUs'>
                <a
                  href='https://herp.careers/v1/iva0403'
                  target='_blank'
                  rel='noreferrer'
                  data-aos='goin'
                >
                  <button className='nav-button button' type='button'>
                    <div className='nav-buttonText'>
                      職種を見る
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
                <a
                  href='https://www.wantedly.com/companies/company_6154268'
                  target='_blank'
                  rel='noreferrer'
                  data-aos='goin'
                >
                  <button className='nav-button button' type='button'>
                    <div className='nav-buttonText'>
                      Wantedly 採用ページ
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = Array.from(
      document.querySelectorAll('.title-animation')
    ) as HTMLElement[]
    const reverseAnimationElement = Array.from(
      document.querySelectorAll('.title-reverse-animation')
    ) as HTMLElement[]
    const windowHeight = window.innerHeight

    const elements = [...animationElements, ...reverseAnimationElement]
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (element) {
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 10

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add('active')
        } else {
          element.classList.remove('active')
        }
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  private readonly expandDescription = (
    element: HTMLDivElement,
    accordionName: string,
    accordionIndex: number
  ): void => {
    const accordionIconDowns = document.querySelectorAll(
      '.' + accordionName + '-accordion-icon-down'
    )
    const accordionContents = document.querySelectorAll('.' + accordionName + '-accordion-content')
    // accordion icon
    accordionIconDowns.forEach((accordionIconDown, index) => {
      const accordionIconDownEl = accordionIconDown as HTMLDivElement
      if (index === accordionIndex) {
        const accordionIconDownRotate = accordionIconDownEl
          ? accordionIconDownEl.style.transform
          : ''
        if (accordionIconDownRotate === '') accordionIconDownEl.style.transform = 'rotate(180deg)'
        else accordionIconDownEl.style.transform = ''
      } else accordionIconDownEl.style.transform = ''
    })
    // accordion content
    accordionContents.forEach((accordionContent, index) => {
      const accordionContentEl = accordionContent as HTMLDivElement
      // 判斷是不是自己被點選
      if (index === accordionIndex) {
        const accordionContentDrop = accordionContentEl.style.height !== ''
        // 判斷是否是已被點選的狀態
        if (accordionContentDrop) {
          accordionContentEl.style.height = ''
          accordionContentEl.style.opacity = ''
        } else {
          const accordionHeight = accordionContentEl.scrollHeight - 5
          accordionContentEl.style.height = String(accordionHeight) + 'px'
          accordionContentEl.style.opacity = '100'
        }
      } else {
        accordionContentEl.style.height = ''
        accordionContentEl.style.opacity = ''
      }
    })
  }
}

export default Careers
