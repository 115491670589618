import React, { Component, Fragment, type ReactElement } from 'react'
import company from '../../images/company.mp4'
import Footer from '../../common/Footer/Footer'
import NavigationLink from '../../common/NavigationLink/NavigationLink'
import WhiteTitle from '../../common/WhiteTitle/WhiteTitle'
import images from '../../common/images'
import { leaderships } from './Leadership/Leadership'
import { advisor } from './Leadership/Advisor'
import LeadershipCard from './Leadership/LeadershipCard'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import BackToTop from '../../common/BackToTop/BackToTop'
import LoadingState from '../../common/LoadingState/LoadingState'
import './style.scss'

class Company extends Component {
  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜会社概要'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
  }

  render(): ReactElement {
    return (
      <Fragment>
        <div className='company main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <WhiteTitle
            pageTitle={images.companyPageTitle}
            title=''
            title2=''
            titleWidth='160px'
            subtitle=''
            subtitle2=''
          />
          <div style={{ position: 'relative' }}>
            <video autoPlay={true} controls={false} muted loop={true} playsInline width='100%'>
              <source src={company} type='video/mp4' />
              Sorry, your browser does not support videos.
            </video>
            <div className='careers-videoTitle'>
              <div className='careers-videoTable'>
                <span className='careers-videoTableLeft'>
                  <img alt='careers-vision' src={images.careersVision} />
                </span>
                <span className='careers-videoTableRight'>私たちのミッション</span>
              </div>
              <span className='careers-videoDivider'></span>
              <div className='careers-titleWrapper'>
                <div className='careers-text'>
                  <h1>AI xヒトで、</h1>
                  <h1>世界にもっと</h1>
                  <h1>輝きを。</h1>
                </div>
                <div className='careers-text'>
                  <p className='mb-3 mr-1'>AI-DRIVEN and HUMAN-POWERED</p>
                  <p>for dynamic future.</p>
                </div>
              </div>
            </div>
          </div>

          <div className='company-content content-padding container section-animation'>
            <p className='mb-0'>
              IVA
              は、世界中に蔓延する偽造品の脅威から、マーケットの安全性を担保するリーディングカンパニーです。
            </p>
            <p className='mb-0'>
              オンラインでの購買活動が一般化する現代において、その利便性を最大限に享受するには「安心」が必要不可欠です。
            </p>
            <p className='mb-0'>
              「真贋鑑定のプロフェッショナル」として歩むIVAは、莫大なデータベースを基にした卓越した人工知能（AI)と、世界最高峰の技術者(ヒト)の力を結集させることで、これからの時代の消費者の購買プロセスに劇的な変化をもたらします。
            </p>
          </div>

          <div className='company-dividing-line container'></div>

          <div className='company-aboutUs section-content content-padding container align-items-center'>
            <div className='title-wrapper'>
              <span className='title-with-bg-wrapper'>
                <i className='title-animation'></i>
                <span className='title-with-bg'>VISION</span>
                <span className='subtitle-with-bg'>私たちのビジョン</span>
              </span>

              <h1 style={{ fontWeight: '700' }}>
                55兆円市場をハックし、
                <br />
                経済のポテンシャルを解放する
              </h1>
            </div>
            <div>
              <p className='mb-0'>モノの価値が毀損されず、適切に評価される世界を実現する。</p>
              <p className='mb-0'>
                そのために私たちは、世界の価値基準に「新たな常識」をつくります。
              </p>
              <p className='mb-0'>
                現在、世界中のモノは偽造品の脅威に晒されることで、本来の価値や魅力が埋もれたままになっています。
              </p>
              <p className='mb-0'>
                私たちは業界の先駆者として、この巨大な偽造品の脅威に立ち向かっていきます。
              </p>
              <p className='mb-0'>
                この脅威を解消することで、モノの価値を最大化し、経済のポテンシャルを解放。
              </p>
              <p className='mb-0'>そんな未来を実現するために、私たちは挑み続けます。</p>
            </div>
          </div>

          <div className='company-aboutUsAndLeadership'>
            <div className='company-aboutUs content-padding container title-wrapper'>
              <span className='title-with-bg-wrapper'>
                <i className='title-animation'></i>
                <span className='title-with-bg'>ABOUT US</span>
                <span className='subtitle-with-bg'>会社概要</span>
              </span>

              <div className='company-aboutUsTable section-animation'>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>会社名</span>
                    <span>IVA株式会社 (IVA Co.,Ltd.)</span>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent company-aboutUsRowLarge'>
                    <span className='company-aboutUsRowTitle'>所在地</span>
                    <div>
                      <p>150-6145 東京都渋谷区渋谷2-24-12 渋谷スクランブルスクエア 45F</p>
                    </div>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent company-aboutUsRowLarge'>
                    <span className='company-aboutUsRowTitle'>代表者</span>
                    <div>
                      <p>相原嘉夫</p>
                    </div>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>設立</span>
                    <span>2019年4月</span>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>事業内容</span>
                    <span>AI×ヒトによる真贋鑑定サービス「フェイクバスターズ」の開発及び運営</span>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>従業員数</span>
                    <span>97名（2025年3月現在）</span>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>株主</span>
                    <div className='company-aboutUsLargeContent pt-0'>
                      <p className='mb-0'>経営陣</p>
                      <p className='mb-0'>株式会社メルカリ</p>
                      <p className='mb-0'>De Capital株式会社</p>
                      <p className='mb-0'>個人投資家</p>
                    </div>
                  </div>
                </div>
                <div className='company-aboutUsRow'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>顧問</span>
                    <div className='company-aboutUsLargeContent pt-0'>
                      <p className='mb-0'>弁護士法人GVA法律事務所</p>
                      <p className='mb-0'>AZX社会保険労務士事務所</p>
                      <p className='mb-0'>ASC社会保険労務士法人</p>
                      <p className='mb-0'>TIP税理士法人</p>
                    </div>
                  </div>
                </div>
                <div className='company-aboutUsRow company-rowWithLink'>
                  <div className='company-aboutUsRowContent'>
                    <span className='company-aboutUsRowTitle'>主要取引先</span>
                    <div className='company-aboutUsLargeContent pt-0'>
                      <p className='mb-0'>株式会社メルカリ</p>
                      <p className='mb-0'>ブックオフコーポレーション株式会社</p>
                      <p className='mb-0'>株式会社SODA(スニーカーダンク)</p>
                      <p className='mb-0'>楽天グループ株式会社(楽天ラクマ)</p>
                      <p className='mb-0'>株式会社丸井グループ</p>
                      <p className='mb-0'>株式会社ジラフ(magi)</p>
                      <p className='mb-0'>株式会社STAYGOLD(BRING)</p>
                      <p className='mb-0'>株式会社ハードオフコーポレーション</p>
                      <p className='mb-0'>株式会社テイツー(古本市場)</p>
                      <p className='mb-0'>台灣娜克阜股份有限公司(Area02)</p>
                      <p className='mb-0'>子佳國際企業有限公司(YOUNG AMERICANA SUPPLY)</p>
                      <p className='mb-0'>公公售完售完株式会社(OUT OF STOCK)</p>
                      <p className='mb-0'>電貓科技有限公司(UP)</p>
                    </div>
                  </div>
                  <NavigationLink
                    label='業務提携先一覧'
                    link='https://www.fakebusters-iva.com/ja/Partners'
                    shouldOpenNewTab={true}
                  />
                </div>
                <div className='company-aboutUsRow company-rowWithLink'>
                  <div className='company-aboutUsRowContent last-content'>
                    <span className='company-aboutUsRowTitle'>加盟団体</span>
                    <div className='company-aboutUsLargeContent pt-0'>
                      <p className='mb-0'>日本流通自主管理協会（AACD）</p>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='company-leadership content-padding container title-wrapper'>
              <span className='title-with-bg-wrapper'>
                <i className='title-animation'></i>
                <span className='title-with-bg'>LEADERSHIP</span>
                <span className='subtitle-with-bg'>経営陣</span>
              </span>

              <div className='company-leadershipCards section-animation'>
                {leaderships.map((leader) => {
                  return <LeadershipCard key={leader.name} leader={leader} />
                })}
              </div>
            </div>
            <div className='company-leadership content-padding container title-wrapper'>
              <span className='title-with-bg-wrapper'>
                <i className='title-animation'></i>
                <span className='title-with-bg'>ADVISOR</span>
                <span className='subtitle-with-bg'>外部顧問</span>
              </span>

              <div className='section-animation company-leadershipCards'>
                {advisor.map((leader) => {
                  return <LeadershipCard key={leader.name} leader={leader} />
                })}
              </div>
            </div>
          </div>
          <div className='company-globalOffices content-padding container'>
            <div className='company-globalOfficesTitleWrapper'>
              <div className='title-wrapper'>
                <span className='title-with-bg-wrapper'>
                  <i className='title-animation'></i>
                  <span className='title-with-bg'>GLOBAL OFFICES</span>
                  <span className='subtitle-with-bg'>拠点</span>
                </span>
              </div>
              {/* <p className='company-officesHeadline jp-14'>
              日本とインターナショナルな人材の強みを融合させ、質とスピードを持ち合わせる競争に強い組織を世界各地に保有しています。
            </p> */}
            </div>
            <div className='company-globalOfficesCards section-animation'>
              <div className='company-globalOfficesCard'>
                <div>
                  <img alt='company-japan-office' src={images.japanOffice} />
                  <p className='company-officeCardTitle' style={{ marginTop: '10px' }}>
                    日本東京
                    <br />
                    本社
                  </p>
                </div>
                <div>
                  <p className='company-officeCardContent'>
                    150-6145 東京都渋谷区渋谷2-24-12
                  </p>
                  <p className='company-officeCardContent'>
                    渋谷スクランブルスクエア 45F
                  </p>
                  <p className='company-officeCardContent'>
                    SHIBUYA SCRAMBLE SQUARE 45F, 2-24-12, Shibuya, Shibuya-Ku, Tokyo, Japan
                  </p>
                </div>
              </div>

              <div className='company-globalOfficesCard'>
                <div>
                  <img alt='company-japan-office' src={images.newJapanOffice} />
                  <p className='company-officeCardTitle' style={{ marginTop: '10px' }}>
                    日本東京
                    <br />
                    ロジスティクスセンター
                  </p>
                </div>
                <div>
                  <p className='company-officeCardContent'>
                    150-0011 東京都渋谷区東2-22-5 SHIBUROJI 3F
                  </p>
                  <p className='company-officeCardContent'>
                    SHIBUROJI 3F, 2-22-5 Higashi, Shibuya-Ku, Tokyo, Japan
                  </p>
                </div>
              </div>

              <div className='company-globalOfficesCard'>
                <div>
                  <img
                    alt='company-japan-office'
                    src={images.taiwanOffice}
                    srcSet={`${images.taiwanOffice} x2`}
                  />
                  <p className='company-officeCardTitle' style={{ marginTop: '10px' }}>
                    台湾台北
                    <br />
                    テクニカルオフィス
                  </p>
                </div>
                <p className='company-officeCardContent'>
                  12F., No. 46, Huaide St., Beitou Dist., Taipei City 112041 , Taiwan (R.O.C.)
                </p>
              </div>
              <div className='company-globalOfficesCard'>
                <div>
                  <img alt='company-japan-office' src={images.chinaOffice} />
                  <p className='company-officeCardTitle' style={{ marginTop: '10px' }}>
                    中国上海 <br />
                    智别云鉴科技有限公司
                  </p>
                </div>
                <p className='company-officeCardContent'>
                  Building 20, No. 1-42, Lane 83, Hongxiang North Road, Wanxiang Town, Pudong New
                  Area, Shanghai, China
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = document.querySelectorAll('.title-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }
}

export default Company
